import React, { useState, useEffect } from "react"
import { useStoreState, useStoreActions } from "easy-peasy"
// import { parseOffer } from "@services/prismic/helpers"
import Box from "@lib/ui/core/Box"
import Hidden from "@lib/ui/core/Hidden"
import Typography from "@lib/ui/core/Typography"
import Layout from "@layouts/MainLayout"
import Container from "@layouts/Container"
import SEO from "@components/SEO"
import Divider from "@components/Divider"
import Top from "@components/Home/Top"
import SearchInput from "@components/Search/SearchInput"
import FilteredBanner from "@components/FilteredBanner"
import Spinner from "@components/Spinner"
import FilteredResult from "@components/Search/FilteredResult"
import { useAllCreditCard } from '@hooks/use-all-credit-card'
import SearchFilter from "@components/Search/SearchFilter"
import { parseOffer, parseSiteConfig } from "@services/prismic/helpers"
import { getQueryString, delParam, getQueryArray } from '@utils/url'
import { getSearchCategories, getSearchNeighborhoods, getSearchCreditCards } from "@services/prismic"
import qs from 'qs'

// import Subscription from '@components/Subscription'
// import Announcement from '@components/Announcement'
// import { FeaturedPostList } from '@components/PostList'
// import OfferCarousel from '@components/Home/OfferCarousel'
// import OfferList from '@components/Offer/List'
// import OfferCarousel from '@components/Home/OfferCarousel'
// import OfferShowMore from '@components/Offer/ShowMore'

const Section = ({ isLast = false, children }) => (
  <Box mb={{ xs: 0, sm: 8 }}>
    <Box pb={{ xs: 4, sm: 0 }}>
      <Container>{children}</Container>
    </Box>
    {!isLast && (
      <Hidden smUp>
        <Divider />
      </Hidden>
    )}
  </Box>
)

const IndexPage = ({ data, location }) => {
  // const  = useStoreState(
  //   ({ offerSearch }) => offerSearch.
  // )
  const searchPayload = useStoreState(
    ({ offerSearch }) => offerSearch.searchPayload
  )
  // const filteredOffer = parseOffer(searchResult)
  // const firstCardInfo = useStoreState(
  //   ({ offerSearch }) => offerSearch.firstCardInfo
  // )
  const searchLoading = useStoreState(
    ({ offerSearch }) => offerSearch.isLoading
  )
  const [total, setTotal] = useState(0)

  // Load Credit Cards at index page
  const updateCreditCards = useStoreActions(({ allCreditCards }) => allCreditCards.update);
  const allCreditCardData = useAllCreditCard()
  const updateKeyword = useStoreActions(({ offerSearch }) => offerSearch.updateKeyword);
  const updateSubcatChosen = useStoreActions(({ offerSearch }) => offerSearch.updateSubcatChosen)
  const updateSubcatPayload = useStoreActions(({ offerSearch }) => offerSearch.updateSubcatPayload);
  const updateNeighborhoodPayload = useStoreActions(({ offerSearch }) => offerSearch.updateNeighborhoodPayload);
  const updateNeighborhoodChosen = useStoreActions(({ offerSearch }) => offerSearch.updateNeighborhoodChosen)
  const updateCardChosen = useStoreActions(({ offerSearch }) => offerSearch.updateCardChosen)
  const updateCardPayload = useStoreActions(({ offerSearch }) => offerSearch.updateCardPayload)
  const updatePageNumber = useStoreActions(({ offerSearch }) => offerSearch.updatePageNumber);

  if (!data) 
    return null

  const sponsoredOffers = parseSiteConfig(data.siteConfig.nodes[0].data.offer_listing_page_sponsor_offers)
  sponsoredOffers.forEach(function(sponsorItem){
    sponsorItem.is_sponsored = true
  });

  const urlParam = getQueryArray()
  const { page, ...restParam } = urlParam
  const filterParams = qs.stringify(restParam, { encode: false, arrayFormat: 'repeat' })

  useEffect(() => {
    
    const updateUrlCategories = async () => {
      let { results } = await getSearchCategories(urlParam['offer_subcategory_id'])
      updateSubcatChosen(results)
      updateSubcatPayload(Object.values(results).map((subcat) => subcat.prismicId))
    }

    const updateUrlNeighborhoods = async () => {
      let { results } = await getSearchNeighborhoods(urlParam['neighborhood_id'])
      updateNeighborhoodChosen(results)
      updateNeighborhoodPayload(Object.values(results).map((location) => location.prismicId))
    }

    const updateUrlCreditCards = async () => {
      let { results } = await getSearchCreditCards(urlParam['creditcard_id'])
      updateCardChosen(results)
      updateCardPayload(Object.values(results).map((card) => card.prismicId))
    }
    if( urlParam['offer_subcategory_id'] != undefined ) { updateUrlCategories() }
    if( urlParam['neighborhood_id'] != undefined ) { updateUrlNeighborhoods() }
    if( urlParam['creditcard_id'] != undefined ) { updateUrlCreditCards() }
    updatePageNumber(urlParam['page'] === undefined ? 1 : urlParam['page'])
    updateKeyword(getQueryString('q'))
    updateCreditCards(allCreditCardData)
  }, [location])

  return (
    <Layout>
      <SEO />
      <Container>
        <Box display="none" position="relative" mt="-1.5rem" mb={{ xs: 2.5, sm: 4 }}>
          <SearchInput height="10rem" />
        </Box>
      </Container>
      <Container>
        <Box mb={2} mt={4}>
          <Typography variant="h2">最新優惠</Typography>
        </Box>
        <SearchFilter height="3rem" />
        {/* <Container px={{ xs: 0, sm: 3 }}>
          <Box mb={{ xs: 0, sm: 4 }}>
            <Announcement />
          </Box>
        </Container> */}
        {searchLoading ? (
          <Box display="flex" justifyContent="center" my={4}>
            <Spinner />
          </Box>
        ) : (
          <>
            <Box mb={{ xs: 2, sm: 4 }}>
              {/* {searchPayload.creditcard_id.length === 1 && searchPayload.offer_subcategory_id.length === 0 && searchPayload.neighborhood_id.length === 0 ? (
                <SingleCardBanner
                  selectedCard={firstCardInfo[0]}
                  number={total}
                />
              ) : ( */}
              {(searchPayload.creditcard_id.length > 0 || searchPayload.offer_subcategory_id.length > 0 || searchPayload.neighborhood_id.length > 0 || searchPayload.q)
                && <FilteredBanner number={total} />}
              {/* )} */}
            </Box>
            <Section isLast>
              <Box>
                <FilteredResult setTotal={setTotal} pageNumber={searchPayload.page} sponsoredOffers={sponsoredOffers} filterParams={filterParams} />
              </Box>
            </Section>
          </>
        )}
      </Container>
      {/* <Section>
        <Box pt={{ xs: 4, sm: 0 }}>
          <Subscription />
        </Box>
      </Section> */}
      {/* <Section isLast>
        <FeaturedPostList />
      </Section> */}
    </Layout>
  )
}

export default IndexPage

export const query = graphql`
  query OffersListingQuery {
    siteConfig: allPrismicSiteConfig {
      nodes {
        data {
          offer_listing_page_sponsor_offers {
            data: offer {
              url
              uid
              id
              isBroken
              document {
                ... on PrismicOffer {
                  prismicId
                  uid
                  type
                  data {
                    offer_category {
                      document {
                        ... on PrismicOfferCategory {
                          data {
                            name
                          }
                          uid
                          type
                        }
                      }
                    }
                    offer_subcategory {
                      document {
                        ... on PrismicOfferSubcategory {
                          uid
                          type
                          data {
                            name
                            offer_category {
                              uid
                              type
                            }
                          }
                        }
                      }
                    }
                    featured_images {
                      image {
                        thumbnails {
                          thumbnail {
                            fluid(maxWidth: 240) {
                              ...GatsbyPrismicImageFluid_noBase64
                            }
                          }
                        }
                        url
                        alt
                      }
                    }
                    merchants {
                      merchant {
                        document {
                          ... on PrismicMerchant {
                            uid
                            data {
                              name
                            }
                          }
                        }
                      }
                    }
                    banks {
                      bank {
                        document {
                          ... on PrismicBank {
                            uid
                            prismicId
                            data {
                              name
                            }
                          }
                        }
                      }
                    }
                    credit_cards {
                      credit_card {
                        document {
                          ... on PrismicCreditCard {
                            uid
                            prismicId
                            data {
                              name
                              card_image {
                                alt
                                url
                                dimensions {
                                  width
                                  height
                                }
                                fixed(height: 42) {
                                  ...GatsbyPrismicImageFixed_noBase64
                                }
                              }
                            }
                          }
                        }
                      }
                    }
                    short_description {
                      text
                    }
                    tagged {
                      tag {
                        uid
                        type
                        document {
                          ... on PrismicTag {
                            id
                            data {
                              name
                            }
                          }
                        }
                      }
                    }
                    title
                    to(formatString: "LL", locale: "zh-tw")
                  }
                }
              }
            }
          }
        }
      }
    }
  }
`
